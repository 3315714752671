import React from 'react';
import cn from 'classnames';
import LinkButton from 'src/components/common/LinkButton';

interface Props {
  link: string;
  onClick?: () => void;
}

export default function CompleteCourse({ link, onClick }: Props) {
  return (
    <div className={cn('next-chapter')}>
      <style jsx={true}>{`
        .next-chapter {
          @p: .pb60, .flex, .justifyBetween, .itemsCenter, .w100;
        }
        .left {
          max-width: 420px;
        }
        h2 {
          @p: .f20, .fw6, .black80, .mt6;
        }
        h3 {
          @p: .black30, .f14, .ttu, .fw6;
        }
        .top {
          @p: .flex;
        }
        .top img {
          @p: .mr10, .relative;
          top: -2px;
          height: 42px;
        }
        p {
          @p: .f16, .black30, .lhCopy, .mt16;
        }
        .btn {
          @p: .mt0;
        }
        @media (max-width: 580px) {
          .left {
            @p: .mb20;
          }
          div.next-chapter {
            @p: .pb38, .db, .bbox;
          }
          div h2 {
            font-size: 20px !important;
          }
          div.btn {
            @p: .mt25;
          }
        }
      `}</style>
      <div className="left">
        <div className="top">
          <img src={require('../../assets/graphics/next-chapter.png')} alt="" />
          <div>
            <h3>Last lesson</h3>
            <h2>This is the last lesson of the course!</h2>
          </div>
        </div>
      </div>
      <LinkButton rounded={true} to={link} onClick={onClick} size="large">
        Complete the Course
      </LinkButton>
    </div>
  );
}
