import * as React from 'react';
import { graphql } from 'gatsby';
import 'src/styles/prism-ghcolors.css';
import TutorialContainer from 'src/components/Tutorials/TutorialContainer';
import { TutorialPageQuery } from 'types/graphql-types';
import Markdown from 'src/components/Tutorials/Markdown';
import NextChapter from 'src/components/Quiz/NextChapter';
import { useCompletedCourses } from 'src/utils/completeCourse';
import { useAuth0 } from 'src/utils/auth';
import CompleteCourse from 'src/components/Quiz/CompleteCourse';

interface Props {
  data: TutorialPageQuery;
  history: any;
}

export default ({ data, history }: Props) => {
  const post = data.mdx;
  const [_, setCompletedCourses] = useCompletedCourses();
  const { loading, user } = useAuth0();
  const completeCourse = (slug: string) => {
    if (loading || !user) return;
    setCompletedCourses(slug);
  };

  return (
    <TutorialContainer history={history} course={data.course} module={data.module} post={data.mdx}>
      <style jsx={true}>
        {`
          .title {
            @p: .mb38;
            font-family: 'Zilla Slab';
          }
        `}
      </style>
      <TutorialContainer.Body>
        <h1 className="title">{post?.frontmatter?.title}</h1>
        <Markdown>{post?.body || ''}</Markdown>
        {post ? (
          post.nextLesson ? (
            <NextChapter
              step={{
                link: post.nextLesson.fields?.slug!,
                title: post.nextLesson.frontmatter?.title!,
                description: post.nextLesson.frontmatter?.description || undefined,
              }}
              onClick={() => completeCourse(post.fields?.slug || '')}
            />
          ) : (
            <CompleteCourse onClick={() => completeCourse(post.fields?.slug || '')} link="/profile" />
          )
        ) : null}
      </TutorialContainer.Body>
    </TutorialContainer>
  );
};

export const pageQuery = graphql`
  query TutorialPage($id: String!, $courseId: String!, $moduleId: String) {
    mdx(id: { eq: $id }) {
      body
      fields {
        slug
      }
      tableOfContents
      excerpt(pruneLength: 140)
      nextLesson {
        fields {
          slug
        }
        frontmatter {
          title
          description
        }
      }
      frontmatter {
        title
        description
        pageTitle
      }
    }
    course(id: { eq: $courseId }) {
      ...CourseData
    }
    module(id: { eq: $moduleId }) {
      id
      name
    }
  }
`;
