import * as React from 'react';
import { MDXRenderer, MDXRendererProps } from 'gatsby-plugin-mdx';
import 'katex/dist/katex.min.css';

interface Props extends MDXRendererProps {}

export default function Markdown(props: Props) {
  return (
    <div className="markdown">
      <style jsx={true}>{`
        .markdown {
          @p: .pb38;
        }
      `}</style>
      <MDXRenderer {...props} />
    </div>
  );
}

/*<div dangerouslySetInnerHTML={{ __html: post.html }} />*/
